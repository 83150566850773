import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import PhoneIcon from "../icons/phone.svg"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Logo from '../icons/logo.svg'

function jumpToContact (e) {
  e.preventDefault()
  scrollTo('#contact')
}
const {useState} = React;

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(true)

  const clickHandler = () => {
    setOpen(!open)
  }

  return (
    <header>
      <nav className="navbar navbar is-fixed-top is-transparent" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <Logo width="215" height="42" />
              </Link>


              <a href="#menu" role="button" className={'navbar-burger burger' + (open ? '' : ' is-active')} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={clickHandler}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div id="navbarBasicExample" className={`navbar-menu ${open ? "" : "is-active"}`}>
              <div className="navbar-end">
                <Link className="navbar-item" to="/o-firmie">O Firmie</Link>
                <Link className="navbar-item" to="/oferta">Oferta</Link>
                <Link className="navbar-item" to="/cennik">Cennik</Link>
                <a href="#contact" className="navbar-item" onClick={(e) => jumpToContact(e)}>Kontakt</a>
                <div className="navbar-item">
                  <a className="button is-outlined is-rounded is-small" href="tel:+48502276292">
                    <span className="icon">
                      <PhoneIcon />
                    </span>
                    <span>502 276 292</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
    </header>
  )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
