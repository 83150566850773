/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import CookieConsent from 'react-cookie-consent'

import Header from "./header"
import Logo from '../icons/logo.svg'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <main>{children}</main>
      <footer className="footer has-text-centered">
        <div className="container">
          <Logo width="215" height="42" />
          <div className="block-space-top">
            © {new Date().getFullYear()} Biuro Rachunkowe Jolanta Saska
            | <Link className="has-text-black" to="/polityka-prywatnosci">Polityka Prywatności</Link>
          </div>
        </div>
      </footer>
      <CookieConsent
                location="bottom"
                buttonText="Zamknij"
                declineButtonText="Decline"
                cookieName="cookieAgreement"
                style={{ background: "#34495e" }}
                buttonStyle={{ background: "#c1b16f", color: "#ffffff", fontSize: "14px", padding: "10px 20px" }}
                >
      Ta strona wykorzystuje pliki cookies. Więcej szczegółów w naszej <Link className="has-text-white underline" to="/polityka-prywatnosci">polityce prywatności</Link>.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
